import { TextField } from '@mui/material';

function TextFieldComponent({ name, onChange, onSubmit }) {
  return (
    <TextField
      fullWidth
      name={name}
      variant="outlined"
      placeholder="Type your image description here"
      color="secondary"
      autoComplete="off"
      style={{
        border: 22,
      }}
      InputProps={{
        style: {
          borderRadius: 12,
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 8,
          paddingRight: 8,
        },
      }}
      onChange={onChange}
      onKeyUp={(e) => {
        if (e.key === 'Enter' && onSubmit !== undefined) {
          onSubmit();
        }
      }}
    />
  );
}

export default TextFieldComponent;
