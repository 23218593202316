import { IconButton } from '@mui/material';
import LineIconComponent from './LineIconComponent';

function IconButtonComponent({
  backgroundColor,
  foregroundColor,
  icon,
  onClick,
}) {
  const fontSize = 14;
  const fontWeight = 600;
  const borderRadius = 8;
  return (
    <IconButton
      onClick={onClick}
      style={{
        textTransform: 'none',
        backgroundColor: backgroundColor,
        color: foregroundColor,
        fontSize: fontSize,
        fontWeight: fontWeight,
        borderRadius: borderRadius,
      }}
      xs={{
        '& .MuiTouchRipple-root .MuiTouchRipple-child': {
          borderRadius: borderRadius + 'px',
        },
      }}
    >
      <LineIconComponent
        icon={icon}
        size={fontSize * 1.5}
        weight={fontWeight}
      />
    </IconButton>
  );
}

export default IconButtonComponent;
