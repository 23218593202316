import SurfaceComponent from '../../components/SurfaceComponent';
import TextComponent from '../../components/TextComponent';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
} from '@mui/material';
import colors from '../../utils/colors';
import ButtonComponent from '../../components/ButtonComponent';
import ImageComponent from '../../components/ImageComponent';
import icons from '../../utils/icons';
import TextFieldComponent from '../../components/TextFieldComponent';
import { useEffect, useState } from 'react';
import useApi from '../../utils/api/useApi';
import { fetchTextToImage } from '../../utils/api/fetchTextToImage';
import { saveAs } from 'file-saver';
import LineIconComponent from '../../components/LineIconComponent';
import { fetchCredits } from '../../utils/api/fetchCredits';
import { apiConfig } from '../../config/apiConfig';

async function onRequestComplete(credits) {
  loadCredits(credits);
}

async function createImage(text, textToImage, credits) {
  if (text !== undefined && text !== '' && credits.json !== null)
    if (!textToImage.isLoading) {
      openAffiliateLink(credits);
      textToImage.setLoading(true);
      var data = await fetchTextToImage({
        text: text,
        onRequestComplete: () => onRequestComplete(credits),
      });
      textToImage.setJson(data.json);
      textToImage.setError(data.error);
      textToImage.setOk(data.ok);
      textToImage.setLoading(false);
    }
}

function openAffiliateLink(credits) {
  if (credits.json !== null && credits.json.data.user_credit > 10) {
    return;
  }

  var item = 'lastAffiliateOpenedDate';

  var currentDate = new Date();
  var lastDate = localStorage.getItem(item);

  var currentDateStr = formatDate(currentDate);

  if (lastDate !== currentDateStr) {
    window.open(apiConfig.affiliateLink, '_blank');
    localStorage.setItem(item, formatDate(currentDate));
    window.focus();
  }
}

function formatDate(date: Date) {
  return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getFullYear()}`;
}

async function loadCredits(credits) {
  if (!credits.isLoading) {
    credits.setLoading(true);
    var data = await fetchCredits();
    credits.setJson(data.json);
    credits.setError(data.error);
    credits.setOk(data.isOk);
    credits.setLoading(false);
  }
}

function DashboardPage() {
  var [imageText, setImageText] = useState();
  var textToImage = useApi();

  const credits = useApi();

  return (
    <SurfaceComponent
      color={colors.cardBackground}
      minHeight={600}
      height="calc(100vh - 48px)"
      borderRadius={16}
      display="flex"
    >
      <Grid container flex="1" direction="column">
        <Grid item flex="0">
          <Header credits={credits} />
        </Grid>
        <Grid item flex="1" display="flex">
          <Body
            imageText={imageText}
            setImageText={setImageText}
            textToImage={textToImage}
            credits={credits}
          />
        </Grid>
      </Grid>
    </SurfaceComponent>
  );
}

function Header({ credits }) {
  useEffect(() => {
    setTimeout(() => loadCredits(credits), 2000);
  }, []);

  return (
    <>
      <Box paddingX={42} paddingY={24}>
        <Grid container>
          <Grid item xs>
            <TextComponent
              fontSize={24}
              fontWeight={600}
              color={colors.onCardBackground}
              variant={'h1'}
            >
              Imagine your picture
            </TextComponent>
          </Grid>
          <Grid item xs="auto" alignItems="center">
            <ButtonComponent
              variant="contained"
              backgroundColor={colors.onBackground}
              foregroundColor={colors.background}
              loadingBackgroundColor={colors.card2Background}
              loading={credits.json === null}
            >
              {credits.json !== null ? credits.json.data.user_credit : ''}
              <Box paddingX={2} />
              <LineIconComponent
                icon={icons.token}
                size={14 * 1.5}
                weight={600}
              />
            </ButtonComponent>
            {/* <TextComponent
              fontSize={18}
              fontWeight={500}
              textAlign={'end'}
              color={colors.onCardBackground}
              variant={'body1'}
            >
              Available:{' '}
              {credits.json !== null ? credits.json.data.user_credit : ''}
            </TextComponent> */}
          </Grid>
        </Grid>
      </Box>
      <Divider color={colors.card2Background} sx={{ borderBottomWidth: 2 }} />
    </>
  );
}

function Body({ imageText, setImageText, textToImage, credits }) {
  const handleImageTextChange = (event) => {
    setImageText(event.target.value);
  };

  const handleImageTextSubmit = () => {
    createImage(imageText, textToImage, credits);
  };

  return (
    <>
      <Grid container flex="1" direction="column">
        <Grid item flex="1" display="flex">
          <SelectedVariation
            model={textToImage}
            imageUrl={textToImage.json?.data?.url}
          />
        </Grid>

        {/* <Grid item md="auto">
        <Divider color={colors.card2Background} orientation="vertical" />
      </Grid> */}
        {/* <Grid item md zeroMinWidth>
        <VariationList />
      </Grid> */}
        <Grid item>
          <Box paddingX={42} paddingY={36}>
            <Grid container>
              <Grid item xs>
                <TextInput
                  onChange={handleImageTextChange}
                  onSubmit={handleImageTextSubmit}
                />
              </Grid>
              <Box paddingX={8}></Box>
              <ButtonComponent
                variant="contained"
                backgroundColor={colors.onBackground}
                foregroundColor={colors.background}
                loadingBackgroundColor={colors.card2Background}
                loading={textToImage.isLoading || credits.json === null}
                onClick={() => handleImageTextSubmit()}
              >
                {'Generate   1'}
                <Box paddingX={2} />
                <LineIconComponent
                  icon={icons.token}
                  size={14 * 1.5}
                  weight={600}
                />
              </ButtonComponent>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

function SelectedVariation({ model, imageUrl }) {
  return (
    // <Grid container paddingX={42} paddingY={38} flex="1">
    // <Grid item xs>
    <Box paddingX={42} paddingY={38} flex="1" display="flex">
      <SurfaceComponent
        color={colors.background}
        borderRadius={16}
        height="100%"
        display="flex"
        flex="1"
      >
        <Box padding={24} display="flex" flex="1">
          {!model.isLoading && model.json === null && model.error === null ? (
            <Grid
              container
              height="100%"
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing="8"
            >
              <Grid item>
                <TextComponent
                  fontSize={16}
                  fontWeight={500}
                  color={colors.onCardBackground}
                  variant={'body1'}
                  textAlign="center"
                >
                  Your imagine picture will be shown here.
                  <br />
                  Type your image description below & Click on generate button
                </TextComponent>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {model.isLoading ? (
            <Grid
              container
              height="100%"
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing="8"
            >
              <Grid item>
                <CircularProgress style={{ color: '#FEFEFE' }} size={24} />
              </Grid>
              <Grid item>
                <TextComponent
                  fontSize={16}
                  fontWeight={500}
                  color={colors.onCardBackground}
                  variant={'body1'}
                >
                  Loading...
                </TextComponent>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {!model.isLoading && !model.isOk && model.error !== null ? (
            <Grid
              container
              height="100%"
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing="8"
            >
              <Grid item>
                <TextComponent
                  fontSize={16}
                  fontWeight={500}
                  color={colors.onCardBackground}
                  variant={'body1'}
                  textAlign="center"
                >
                  {model.error.error_code === 'USER_CREDIT_OVER' ? (
                    <>
                      Your credits are over.
                      <br />
                      You get daily free 10 credits or you can buy more credits
                    </>
                  ) : (
                    <>Something went wrong. Please try again...</>
                  )}
                </TextComponent>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {!model.isLoading && model.isOk && model.json !== null ? (
            <Grid container flex="1" direction="column">
              <Grid item flex="1" display="flex">
                <Box
                  height="100%"
                  width="auto"
                  style={{
                    backgroundImage: `url(${
                      imageUrl ?? '/images/cute_superman.png'
                    })`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    aspectRatio: 1 / 1,
                    borderRadius: 16,
                  }}
                />
              </Grid>
              <Grid item flex="0">
                <Box paddingY={8} />
                <Grid container spacing={16}>
                  <Grid item>
                    <ButtonComponent
                      variant="contained"
                      backgroundColor={colors.onBackground}
                      foregroundColor={colors.background}
                      endIcon={icons.download}
                      onClick={() => saveAs(model.json.data.url)}
                    >
                      Download
                    </ButtonComponent>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
        <Box paddingY={16} />
      </SurfaceComponent>
    </Box>
    // </Grid>
    // </Grid>
    // <Box paddingX={42} paddingY={38} height="100%">
    //   <SurfaceComponent color={colors.background} height="100%">
    //     <Box padding={24}>
    //       {!model.isLoading ? (
    //         <Box>
    //           <ImageComponent
    //             src={imageUrl ?? '/images/cute_superman.png'}
    //             // height={'auto'}
    //             width={'20%'}
    //             borderRadius={styles.cardBorderRadius}
    //           />
    //           <Box paddingY={8} />
    //           <Grid container spacing={16}>
    //             <Grid item>
    //               <ButtonComponent
    //                 variant="contained"
    //                 backgroundColor={colors.onBackground}
    //                 foregroundColor={colors.background}
    //                 endIcon={icons.download}
    //                 onClick={() => saveAs(model.json.data.image_url)}
    //               >
    //                 Download
    //               </ButtonComponent>
    //             </Grid>
    //             <Grid item>
    //               <ButtonComponent
    //                 variant="contained"
    //                 backgroundColor={colors.cardBackground}
    //                 foregroundColor={colors.onCardBackground}
    //                 endIcon={icons.addCircle}
    //               >
    //                 Create variation
    //               </ButtonComponent>
    //             </Grid>
    //           </Grid>
    //         </Box>
    //       ) : (
    //         <LoaderComponent />
    //       )}
    //     </Box>
    //     <Box paddingY={16} />
    //   </SurfaceComponent>
    // </Box>
  );
}

function TextInput({ onChange, onSubmit }) {
  return (
    <TextFieldComponent
      name="imageText"
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
}

function VariationList() {
  return (
    <Box paddingY={36}>
      <Box paddingBottom={16} paddingX={32}>
        <TextComponent
          fontSize={16}
          fontWeight={500}
          color={'white'}
          variant={'h4'}
        >
          Variations
        </TextComponent>
      </Box>
      <VariationItem />
      <VariationItem />
      <VariationItem />
    </Box>
  );
}

function VariationItem() {
  return (
    <Box paddingX={24}>
      <Card elevation={0} style={{ borderRadius: 12 }}>
        <CardActionArea>
          <CardContent style={{ backgroundColor: colors.cardBackground }}>
            <TextComponent
              fontSize={16}
              fontWeight={500}
              color={'white'}
              variant={'h4'}
            >
              Variation 2
            </TextComponent>
            {/* <Box paddingY={4}></Box> */}
            {/* <TextComponent
              fontSize={14}
              fontWeight={500}
              color={'text.secondary'}
              variant={'body1'}
              noWrap
            >
              Lizards are a widespread group of squamate reptiles, with over
              6,000 species, ranging across all continents except Antarctica
            </TextComponent> */}
            <Box paddingY={8}></Box>
            <ImageComponent
              src="/images/cute_superman.png"
              height={undefined}
              width={'100%'}
              aspectRatio={16 / 9}
              borderRadius={12}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default DashboardPage;
