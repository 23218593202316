import { createTheme } from '@mui/material';
import colors from './colors';

const theme = createTheme({
  typography: {
    fontFamily: ['Quicksand'].join(','),
    htmlFontSize: 10,
  },
  spacing: 1,
  palette: {
    mode: 'dark',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: '3px solid ' + colors.card2Background,
          },
          '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: '3px solid ' + colors.onCardBackground,
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              border: '3px solid ' + colors.onCardBackground,
            },
          '& .MuiOutlinedInput-input': {
            color: colors.onCardBackground,
          },
          '&:hover .MuiOutlinedInput-input': {
            color: colors.onCardBackground,
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: colors.onCardBackground,
          },
          '& .MuiInputLabel-outlined': {
            color: 'green',
          },
          '&:hover .MuiInputLabel-outlined': {
            color: 'red',
          },
          '& .MuiInputLabel-outlined.Mui-focused': {
            color: 'purple',
          },
        },
      },
    },
  },
});

export default theme;
