import { Typography } from '@mui/material';

function TextComponent({
  children,
  variant,
  fontSize,
  fontWeight,
  color,
  noWrap,
  style,
  textAlign,
  width,
}) {
  return (
    <Typography
      variant={variant}
      fontSize={fontSize / 10 + 'rem'}
      fontWeight={fontWeight}
      color={color}
      noWrap={noWrap}
      style={style}
      textAlign={textAlign}
      width={width}
    >
      {children}
    </Typography>
  );
}

export default TextComponent;
