import { apiConfig } from '../../config/apiConfig';
import { fetchData } from './fetchData';

export async function fetchProfile() {
  var data = await fetchData(apiConfig.getUserProfile, {
    method: 'GET',
  });

  return { json: data.json, error: data.error, ok: data.ok };
}
