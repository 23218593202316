import { useState } from 'react';

const useApi = () => {
  const [json, setJson] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isOk, setOk] = useState(false);
  const [error, setError] = useState(null);

  return { json, setJson, isLoading, setLoading, isOk, setOk, error, setError };
};

export default useApi;
