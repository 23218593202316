import { Paper, Box } from '@mui/material';
import styles from '../utils/styles';

function SurfaceComponent({
  children,
  color,
  borderRadius,
  minHeight,
  height,
  display,
  flex,
}) {
  borderRadius ??= styles.cardBorderRadius;
  return (
    <Paper
      elevation={0}
      style={{
        borderRadius: borderRadius,
        background: color,
        minHeight: minHeight,
        height: height,
        display: display,
        flex: flex,
        flexDirection: 'column',
      }}
    >
      {children}
    </Paper>
  );
}

export default SurfaceComponent;
