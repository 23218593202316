import { ThemeProvider, Paper, Box } from '@mui/material';
import './App.css';

import AppRoutes from './pages/AppRoutes';

// MSAL imports
import { MsalProvider, useMsal } from '@azure/msal-react';
import theme from './utils/theme';
import colors from './utils/colors';

function App({ pca }) {
  return (
    <ThemeProvider theme={theme}>
      <MsalProvider instance={pca}>
        <Paper
          style={{
            background: colors.background,
            minHeight: '100vh',
            minWidth: 1024 + 24,
          }}
          square
        >
          <Box padding={24}>
            <AppRoutes />
          </Box>
        </Paper>
      </MsalProvider>
    </ThemeProvider>
  );
}

function Home() {
  const { instance, accounts, inProgress } = useMsal();

  return (
    <div className="App">
      {(() => {
        if (accounts.length > 0) {
          return (
            <>
              <span>
                There are currently {accounts.length} users signed in!
              </span>
              <button onClick={() => instance.logoutRedirect()}>Logout</button>
            </>
          );
        } else if (inProgress === 'login') {
          return <span>Login is currently in progress!</span>;
        } else {
          return (
            <>
              <span>There are currently no users signed in!</span>
              <button onClick={() => instance.loginRedirect()}>Login</button>
            </>
          );
        }
      })()}
    </div>
  );
}

export default App;
