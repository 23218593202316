const colors = {
  background: '#141718',
  onBackground: '#FEFEFE',
  cardBackground: '#232627',
  onCardBackground: '#FEFEFE',
  card2Background: '#343839',
  primary: '#0084ff',
};

export default colors;
