// MSAL imports
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

function LogoutPage() {
  const { instance } = useMsal();

  useEffect(() => {
    instance.logout();
  }, []);

  return <></>;
}

export default LogoutPage;
