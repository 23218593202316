import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

function LoginPage() {
  const { instance } = useMsal();

  useEffect(() => {
    instance.loginRedirect();
  }, []);

  return <></>;
}

export default LoginPage;
