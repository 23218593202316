import { Box } from '@mui/material';

function ImageComponent({
  src,
  height,
  maxHeight,
  width,
  borderRadius,
  aspectRatio,
  flex,
}) {
  return (
    <Box
      component="img"
      height={height}
      width={width}
      src={src}
      style={{
        borderRadius: borderRadius,
        // aspectRatio: aspectRatio,
        objectFit: 'contain',
        flex: flex,
      }}
    />
  );
}

export default ImageComponent;
