import { apiConfig } from '../../config/apiConfig';
import { fetchData } from './fetchData';

export async function fetchTextToImage({ text, onRequestComplete }) {
  var data = await fetchData(apiConfig.textToImage, {
    method: 'POST',
    body: {
      caption: text,
    },
  });

  // if (!(data.ok && data.json !== null)) {
  //   return { json: data.json, error: data.error, ok: data.ok };
  // }

  onRequestComplete();

  if (data.ok && data.json !== null && data.json.data.url !== undefined) {
    return { json: data.json, error: data.error, ok: data.ok };
  }

  // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  // while (true) {
  //   await delay(10000);
  //   var status = await fetchData(apiConfig.textToImageStatus, {
  //     method: 'POST',
  //     body: { id: data.json.data.id },
  //   });
  //   if (
  //     status.ok &&
  //     status.json !== null &&
  //     status.json.data.image_url !== undefined
  //   ) {
  //     return { json: status.json, error: status.error, ok: status.ok };
  //   }
  // }
}
