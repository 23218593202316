function LineIconComponent({ icon, weight, size }) {
  return (
    <span
      className="material-symbols-rounded"
      style={{
        fontSize: size,
        fontVariationSettings: `'FILL' 0, 'wght' ${weight}, 'GRAD' 0, 'opsz' 48`,
      }}
    >
      {icon}
    </span>
  );
  //   return <i class={icon} style={{ fontSize: size }} />;
}

export default LineIconComponent;
