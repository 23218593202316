const icons = {
  download: 'download',
  addCircle: 'add_circle',
  add: 'add',
  remove: 'remove',
  token: 'token',
  exit: 'exit_to_app',
};

export default icons;
