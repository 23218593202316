const baseUrl = 'https://text-to-image-func.azurewebsites.net';
const api = baseUrl + '/api';
const textToImage = api + '/text_to_image';

export const apiConfig = {
  affiliateLink: 'https://short-ly.azurewebsites.net/api/geo-link',
  textToImage: 'https://text-to-image-func.azurewebsites.net/api/text_to_image',
  textToImageStatus:
    'https://text-to-image-func.azurewebsites.net/api/text_to_image_status',
  createOrder:
    'https://text-to-image-func.azurewebsites.net/api/create_order_api',
  getCredits:
    'https://text-to-image-func.azurewebsites.net/api/get_user_credits',
  getUserProfile:
    'https://text-to-image-func.azurewebsites.net/api/get_user_profile',
  paymentSuccessRedirect:
    'https://texttoimagewebhook.azurewebsites.net/api/payment_success_webhook',
};
