import { Link } from 'react-router-dom';
import { routeConfig } from '../../config/routeConfig';

function NotFoundPage() {
  return (
    <Link to={routeConfig.default}>
      <button>Go to home</button>
    </Link>
  );
}

export default NotFoundPage;
