import { apiConfig } from '../../config/apiConfig';
import { fetchData } from './fetchData';

export async function fetchCreateOrder({ amount, phoneNumber }) {
  var data = await fetchData(apiConfig.createOrder, {
    method: 'POST',
    body: { order_amount: amount, customer_phone: phoneNumber },
  });

  return { json: data.json, error: data.error, ok: data.ok };
}
