import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance } from '../..';

export async function fetchData(url, { method, body }) {
  var json = null;
  var error = null;
  var ok = false;

  const tokenRequest = {
    scopes: [
      'openid',
      '7c0aa874-4211-4c33-be2f-ffd4ae9881c6',
      'offline_access',
    ],
  };

  try {
    var token = await msalInstance.acquireTokenSilent(tokenRequest);
    if (token.accessToken === '') {
      throw Error('Error token empty');
    }
    var res = await fetch(url, {
      method: method,
      headers: {
        authorization: 'Bearer ' + token.accessToken,
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (res.status === 200) {
      var resJson = await res.json();
      json = resJson;
      ok = true;
      return { json, error, ok };
    } else {
      var resJson2 = await res.json();
      json = resJson2;
      ok = false;
      if (
        json !== null &&
        json !== undefined &&
        json.metadata !== undefined &&
        json.metadata.error_code !== undefined
      ) {
        error = json.metadata;
      }
      return { json, error, ok };
    }
    //  else {
    //   var resText = await res.text();
    //   var resJson = await res.json();
    //   throw Error(resJson);
    // }
  } catch (e) {
    error = e.message;
    // console.log(error);

    if (e instanceof InteractionRequiredAuthError) {
      msalInstance.acquireTokenRedirect(tokenRequest);
    }
    return { json, error, ok };
  }
}
