import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import { routeConfig } from '../config/routeConfig';
import DefaultPage from './default/DefaultPage';
import LogoutPage from './logout/LogoutPage';
import LoginPage from './login/LoginPage';
import DashboardPage from './dashboard/DashboardPage';
import NotFoundPage from './not_found/NotFoundPage';
import { Grid, Box } from '@mui/material';
import AppTabBar from './tabbar/AppTabBar';
import { useEffect } from 'react';

function AppRoutes() {
  return (
    <BrowserRouter>
      <Grid container wrap="nowrap" minWidth={1024}>
        <AuthenticatedRoute>
          <Grid item width={360} maxWidth={360}>
            <AppTabBar />
          </Grid>
        </AuthenticatedRoute>
        <Grid item xs zeroMinWidth>
          <Routes>
            <Route
              exact
              path={routeConfig.default}
              element={
                <AuthenticatedRoute>
                  <DefaultPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={routeConfig.login}
              element={
                <UnauthenticatedRoute>
                  <LoginPage />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path={routeConfig.logout}
              element={
                <AuthenticatedRoute>
                  <LogoutPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={routeConfig.dashboard}
              element={
                <AuthenticatedRoute>
                  <DashboardPage />
                </AuthenticatedRoute>
              }
            />
            <Route path={routeConfig.notFound} element={<NotFoundPage />} />
            {/* <Route path="*" element={<AnyPage />} /> */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Grid>
      </Grid>
    </BrowserRouter>
  );
}

function AuthenticatedRoute({ children }) {
  return (
    <>
      <UnauthenticatedTemplate>
        <PageNavigate url={routeConfig.login} />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    </>
  );
}

function UnauthenticatedRoute({ children }) {
  return (
    <>
      <AuthenticatedTemplate>
        <PageNavigate url={routeConfig.dashboard} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>
    </>
  );
}

function PageNavigate({ url }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(url);
  }, []);

  return <></>;
}

export default AppRoutes;
