import LineIconComponent from './LineIconComponent';
import { LoadingButton } from '@mui/lab';

function ButtonComponent({
  children,
  variant,
  backgroundColor,
  foregroundColor,
  endIcon,
  onClick,
  fullWidth = false,
  loading = false,
  loadingBackgroundColor,
  height,
}) {
  const fontSize = 14;
  const fontWeight = 700;

  return (
    <LoadingButton
      onClick={onClick}
      variant={variant}
      loading={loading}
      style={{
        textTransform: 'none',
        backgroundColor:
          loading === true ? loadingBackgroundColor : backgroundColor,
        color: loading === true ? undefined : foregroundColor,
        fontSize: fontSize,
        fontWeight: fontWeight,
        borderRadius: 8,
        height: height,
      }}
      endIcon={
        endIcon !== undefined ? (
          <LineIconComponent
            icon={endIcon}
            size={fontSize * 1.5}
            weight={fontWeight}
          />
        ) : undefined
      }
      fullWidth={fullWidth}
    >
      {children}
    </LoadingButton>
  );
}

export default ButtonComponent;
