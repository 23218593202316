import { Box, CircularProgress, Grid } from '@mui/material';
import TextComponent from '../../components/TextComponent';
import SurfaceComponent from '../../components/SurfaceComponent';
import IconButtonComponent from '../../components/IconButtonComponent';
import LineIconComponent from '../../components/LineIconComponent';
import colors from '../../utils/colors';
import ButtonComponent from '../../components/ButtonComponent';
import icons from '../../utils/icons';
import useApi from '../../utils/api/useApi';
import { fetchCreateOrder } from '../../utils/api/fetchCreateOrder';
import { useEffect, useState } from 'react';
import { fetchProfile } from '../../utils/api/fetchProfile';
import { useNavigate } from 'react-router-dom';
import { routeConfig } from '../../config/routeConfig';
import { apiConfig } from '../../config/apiConfig';

function AppTabBar() {
  return (
    <Box paddingRight={24}>
      <Header />
      <Profile />
    </Box>
  );
}

function Header() {
  return (
    <Box paddingTop={6} paddingBottom={56}>
      <img src="/images/imagify.png" alt="Imagify" width={160}></img>
    </Box>
  );
}

function Profile() {
  const profile = useApi();
  const [amount, setAmount] = useState(5);
  const order = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(loadProfile, 2000);
  }, []);

  async function loadProfile() {
    if (!profile.isLoading) {
      profile.setLoading(true);
      var data = await fetchProfile();
      profile.setJson(data.json);
      profile.setError(data.error);
      profile.setOk(data.ok);
      profile.setLoading(false);
    }
  }

  function incrementAmount() {
    setAmount(amount + 5);
  }

  function decrementAmount() {
    if (amount > 5) {
      setAmount(amount - 5);
    }
  }

  async function buyCoins() {
    if (!order.isLoading) {
      order.setLoading(true);
      var data = await fetchCreateOrder({
        amount: amount * 100,
        phoneNumber: '1234567890',
      });
      order.setJson(data.json);
      order.setError(data.error);
      order.setOk(data.ok);
      order.setLoading(false);

      if (data.ok && data.json !== null) {
        var options = {
          key: 'rzp_live_ikZCFJFrMyDmrY', // Enter the Key ID generated from the Dashboard
          amount: `${amount * 100}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'USD',
          // name: 'Acme Corp', //your business name
          // description: 'Test Transaction',
          // image: 'https://example.com/your_logo',
          order_id: data.json.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          callback_url: apiConfig.paymentSuccessRedirect,
          prefill: {
            // We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
            name: profile.json.data.name, //your customer's name
            email: profile.json.data.email,
            // contact: '9000090000', //Provide the customer's phone number for better conversion rates
          },
          // notes: {
          //   address: 'Razorpay Corporate Office',
          // },
          // theme: {
          //   color: '#3399cc',
          // },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        // const cf = new window.Cashfree(data.json.data.paymentSessionId);
        // cf.redirect();
      }
    }
  }

  async function logout() {
    navigate(routeConfig.logout);
  }

  return (
    <>
      <SurfaceComponent color={colors.cardBackground} borderRadius={16}>
        <Box padding={16}>
          {profile.json === null && (
            <Grid
              container
              height={64}
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress style={{ color: '#FEFEFE' }} size={24} />
            </Grid>
          )}
          {profile.json !== null && (
            <Grid
              container
              height={64}
              alignItems="center"
              justifyContent="start"
              wrap="nowrap"
            >
              <Grid item>
                <Box
                  component="img"
                  src="/images/user_profile.png"
                  alt="Profile"
                  height="auto"
                  width={64}
                />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Box paddingLeft={16}>
                  <Grid container alignItems="center" rowSpacing={4}>
                    <Grid item xs={12}>
                      <TextComponent
                        fontSize={16}
                        fontWeight={600}
                        color={colors.onCardBackground}
                        noWrap={true}
                      >
                        {profile.json.data.name}
                      </TextComponent>
                    </Grid>
                    <Grid item xs={12}>
                      <TextComponent
                        fontSize={12}
                        fontWeight={600}
                        color={colors.onCardBackground}
                        style={{ opacity: 0.6 }}
                        noWrap={true}
                      >
                        {profile.json.data.email}
                      </TextComponent>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
          {profile.json !== null && <Box paddingY={8} />}
          {profile.json !== null && (
            <Grid container>
              <Grid
                container
                xs="auto"
                item
                direction="row"
                alignItems="center"
              >
                <IconButtonComponent
                  backgroundColor={colors.card2Background}
                  foregroundColor={colors.onCardBackground}
                  icon={icons.remove}
                  onClick={() => decrementAmount()}
                />
                <Box paddingX={8} />
                <Box>
                  <TextComponent
                    fontSize={16}
                    fontWeight={500}
                    color={colors.onCardBackground}
                    width={40}
                    textAlign="center"
                  >
                    {'$' + amount}
                  </TextComponent>
                </Box>
                <Box paddingX={8} />
                <IconButtonComponent
                  backgroundColor={colors.card2Background}
                  foregroundColor={colors.onCardBackground}
                  icon={icons.add}
                  onClick={() => incrementAmount()}
                />
              </Grid>
              <Box paddingX={8} />
              <Grid item xs>
                <ButtonComponent
                  variant="contained"
                  backgroundColor={colors.onBackground}
                  foregroundColor={colors.background}
                  loadingBackgroundColor={colors.card2Background}
                  fullWidth={true}
                  loading={order.isLoading}
                  onClick={() => buyCoins()}
                >
                  {'Buy ' + amount * 20}
                  <Box paddingX={2} />
                  <LineIconComponent
                    icon={icons.token}
                    size={14 * 1.5}
                    weight={600}
                  />
                </ButtonComponent>
              </Grid>
            </Grid>
          )}
        </Box>
      </SurfaceComponent>
      <Box paddingY={8} />
      <ButtonComponent
        variant="contained"
        backgroundColor={colors.cardBackground}
        foregroundColor={colors.onCardBackground}
        loadingBackgroundColor={colors.card2Background}
        fullWidth={true}
        onClick={() => logout()}
        endIcon={icons.exit}
        height={48}
      >
        Logout
      </ButtonComponent>
    </>
  );
}

function Token() {
  var orderToken = useApi();

  async function buyToken() {
    if (!orderToken.isLoading) {
      orderToken.setLoading(true);
      var data = await fetchCreateOrder({
        amount: 10,
        phoneNumber: '1234567890',
      });
      orderToken.setJson(data.json);
      orderToken.setError(data.error);
      orderToken.setOk(data.ok);
      orderToken.setLoading(false);

      if (data.ok && data.json !== null) {
        const cf = new window.Cashfree(data.json.data.paymentSessionId);
        cf.redirect();
      }
    }
  }

  return (
    <>
      <SurfaceComponent color={colors.cardBackground} borderRadius={16}>
        <Box padding={16}>
          <ButtonComponent
            variant="contained"
            backgroundColor={colors.onBackground}
            foregroundColor={colors.background}
            endIcon={icons.download}
            onClick={buyToken}
          >
            Buy Token
          </ButtonComponent>
        </Box>
      </SurfaceComponent>
    </>
  );
}

export default AppTabBar;
